<template>
  <div>
    <div class="contentBody">
      <div class="contentBox">
        <div class="title"><span>*</span>反馈内容</div>
        <div class="cont">
          <a-textarea
            v-model="value"
            style="height: 100%; max-height: 500px"
            placeholder="请提出您宝贵的意见~"
            :auto-size="{ minRows: 3, maxRows: 8 }"
          />
        </div>
        <div class="title"><span>*</span>反馈图片</div>
        <div class="upImg">
          <div class="imgs">
            <div class="img" @click="uploadFile" v-if="imgList.length < 9">
              <div class="imgBox iconfont icon-camera_fill"></div>
            </div>
            <div class="img" v-for="(item, index) in imgList">
              <img :src="$imgUrl + item.path_name" />
              <div
                class="iconfont icon-close close"
                @click="imgDelClick(index)"
              ></div>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="serveBox dis-cent" @click="saveClick">提交</div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>

<script>
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  name: "",
  data() {
    return {
      value: "",
      headers: {
        authorization: "authorization-text",
      },
      imgList: [],
    };
  },
  created() {},
  methods: {
    imgDelClick(i) {
      this.imgList.splice(i, 1);
    },
    async uploadFile() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.imgList.push(res.data.data);
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    saveClick() {
      let data = {};
      data.content = this.value;
      let imgs = [];
      for (let i = 0; i < this.imgList.length; i++) {
        const e = this.imgList[i];
        imgs.push(e.id);
      }
      imgs = imgs.join(",");
      data.resource = imgs;
      if (data.content) {
        this.$message.error("请填写内容");
        return
      }
      data = JSON.stringify(data);
      this.$post("/web/feedback_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("提交反馈成功");
          this.$router.go(-1);
        }
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.contentBody {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px 15px;
    .title {
      font-weight: 600;
      font-size: 18px;
      span {
        color: #e00606;
      }
    }
    .cont {
      height: 260px;
      padding-top: 15px;
      margin-bottom: 20px;
    }

    .upImg {
      margin-top: 10px;

      .text {
        margin-left: 20px;
        font-size: 12px;
      }

      .txt {
        margin-left: 5px;
        font-size: 12px;
        color: #999;
      }

      .imgs {
        display: flex;
        margin-top: 15px;
        flex-wrap: wrap;

        .img {
          width: calc(20% - 12px);
          margin-right: 15px;
          padding-top: calc(20% - 12px);
          position: relative;
          margin-bottom: 15px;
          overflow: unset;
          .imgBox {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 5px;
            box-shadow: 0 0 4px 2px #ebebeb;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9c9c9c;
            font-size: 40px;
            cursor: pointer;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
          }

          .close {
            position: absolute;
            top: -3px;
            right: -3px;
            background-color: #b3b3b3;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: #fff;
            cursor: pointer;
          }
        }

        .img:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
    .btnBox {
      border-top: 1px solid #ebebeb;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      .serveBox {
        width: 200px;
        border-radius: 5px;
        height: 45px;
        background-color: #e00606;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
